<template>
  <div class="vps-change">
    <transition name="fade" mode="out-in">
      <base-loader v-if="!configurator.length" />
      <main-card v-else>
        <template v-if="isMountedServer" #header>
          <!--        <template v-if="status === 4 || status === 5 || tariff.in_pay" #header>-->
          <base-alert :title="$t('disabled.title')">
            {{ $t('disabled.text') }}
          </base-alert>
        </template>
        <div class="vps-change-config">
          <component
            :is="componentsByType[item.type]"
            v-for="item in configurator"
            :key="item.intname"
            v-bind="item"
            :view="true"
            :intname="item.intname || item.name"
            :id-lic="pleskPanelId"
            :lic="item.intname === 'pleskpanel' || item.type === 'multiple' ? licType : 'none'"
            :shown-period="tariff.period_id"
            class="vps-change-config__item"
            @change="onChange(item, $event)"
          />
        </div>
        <template #footerEnd>
          <div class="vps-change__footer">
            <div v-if="status !== 4 && status !== 5" class="vps-change__summary medium-title">
              <span class="vps-change__summary-text"> {{ $t(`res.${summaryKey}`) }}: </span>
              <span class="vps-change__summary-sum">
                {{ $n(Math.abs(sum), 'currency') }}
              </span>
              <v-popover
                v-if="!!sum"
                placement="top"
                :auto-hide="true"
                popover-class="vps-change__popover"
                class="vps-change__more"
              >
                <plain-button icon="help" class="vps-change__more-btn" />
                <config-cost-details
                  v-if="detailsData"
                  slot="popover"
                  v-bind="detailsData"
                  class="vps-change__details"
                />
              </v-popover>
            </div>
            <div v-else-if="status === 5" class="vps-change__text">
              {{ $t('disabled.note') }}
            </div>
            <div class="vps-change__order-btns">
              <base-button
                :disabled="status === 4 || status === 5 || tariff.in_pay || isSendingToBasket"
                :loading="isSending"
                class="vps-change__order-btn"
                @click="sum > 0 ? sendToPay() : save()"
              >
                {{ status === 1 || 0 >= sum ? $t('save') : $t('pay') }}
              </base-button>
              <base-button
                v-if="status !== 1 && sum > 0"
                :disabled="
                  !hasChanges || status === 4 || status === 5 || tariff.in_pay || isSending
                "
                :loading="isSendingToBasket"
                theme="outlined"
                class="vps-change__order-btn"
                @click="sendToBasket()"
              >
                {{ $t('basket') }}
              </base-button>
            </div>
          </div>
        </template>
      </main-card>
    </transition>
  </div>
</template>

<script>
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import MainCard from '@/components/MainCard/MainCard.vue';
import SliderBlock from '@/components/Configurator/components/SliderBlock.vue';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock.vue';
import SelectBlock from '@/components/Configurator/components/SelectBlock.vue';
import TextBlock from '@/components/Configurator/components/TextBlock';
import ConfigCostDetails from '../components/ConfigCostDetails';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import { debounce } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import wizardPay from '@/mixins/billmgr/wizardPay';
import handleRedirect from '@/mixins/billing/handleRedirect';
import qs from 'qs';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import MultipleComponent from '@/models/base/components/multiple';
import MultipleBlock from '@/components/Configurator/components/MultipleBlock';
import updateStatus from '@/layouts/VPS/mixins/updateStatus';
export default {
  name: 'VpsConfig',
  components: {
    MainCard,
    SliderBlock,
    MultipleBlock,
    CheckboxBlock,
    SelectBlock,
    ConfigCostDetails,
    BaseAlert,
  },
  mixins: [showErrorModal, wizardPay, handleRedirect, updateServerInfo, updateStatus],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof VpsTariff,
    },
  },
  data() {
    return {
      sum: 0,
      loading: false,
      isSending: false,
      pleskPanelId: '',
      isSendingToBasket: false,
      startFunc: 'payment.add',
      componentsByType: {
        slider: SliderBlock,
        checkbox: CheckboxBlock,
        select: SelectBlock,
        multiple: MultipleBlock,
        text: TextBlock,
      },
      detailsData: null,
      configuratorClass: PaymentMethodConfigurator,
    };
  },
  computed: {
    configurator() {
      const config = this.tariff.configurator.filter(x => x.name !== 'panelid');
      if (!this.serverState) return config;
      if (this.serverState.code === 1) return config;
      const newConfig = [];
      config.forEach(x => {
        console.log(x.name);
        if (x.name.includes('isp_vds_')) {
          x.config.disabled = true;
          newConfig.push(x);
        } else newConfig.push(x);
      });
      return newConfig;
    },
    licType() {
      return this.tariff.licType;
    },
    pleskPanelAddonName() {
      // console.log(this.configurator);
      if (
        this.configurator &&
        this.configurator.length &&
        this.configurator.find(addon => addon.intname === 'pleskpanel') &&
        this.configurator.find(addon => addon.intname === 'pleskpanel').config
      ) {
        return this.configurator.find(addon => addon.intname === 'pleskpanel').name;
      } else return '';
    },
    pricelistId() {
      return this.tariff.pricelistId;
    },
    ext() {
      return this.tariff.addonsExt;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    summaryKey() {
      return this.sum > 0 ? 'pay' : this.sum < 0 ? 'refund' : 'def';
    },
    serverState() {
      return this.tariff.serverState;
    },
    hasChanges() {
      return !!this.sum;
    },
    tools() {
      return this.$store.state.moduleVPS.tools;
    },
    status() {
      return this.tariff.status.code;
    },
    // pleskPanelId() {
    //   return this.provider === '3' ? this.formData[`${this.pleskPanelAddonName}`] : null;
    // },

    isMountedServer() {
      return (
        this.tariff.status.code === 4 ||
        this.tariff.status.code === 5 ||
        (this.tariff.in_pay && this.tariff.in_pay === 'on')
      );
    },
  },
  watch: {
    configurator() {
      if (this.configurator.length) this.calcSum();
    },
    'tariff.status': {
      handler: async function (event) {
        if ([1, 4, 5].includes(event.code)) {
          await this.updateVpsStatus(this.tariff.id);
        }
      },
      deep: true,
      immediate: true,
    },
    pleskPanelAddonName(event) {
      if (event)
        this.pleskPanelId = this.configurator.find(
          addon => addon.intname === 'pleskpanel'
        ).config.value;
    },
    pricelistId(val) {
      if (val) this.fetchExt(val);
    },
  },
  mounted() {
    if (this.pricelistId && !this.ext && this.tariff._serverInfo) this.fetchExt(this.pricelistId);
    if (this.configurator.length) this.calcSum();
    this.updateVpsStatus(this.tariff.id, true);
    // this.$store.dispatch('moduleVPS/fetchNodeList');
  },
  methods: {
    onChange(item, payload) {
      if (item.name === this.pleskPanelAddonName) {
        this.pleskPanelId = payload.value;
      }
      const { value, sum } = payload;
      item.currentValue = value;
      if (item.sum !== sum) {
        item.sum = sum;
        this.calcSum();
      }
    },
    updateObjectInArray(array, id, updatedObject) {
      const objectToUpdate = array.find(obj => obj.id === id);

      if (objectToUpdate) {
        Object.assign(objectToUpdate, updatedObject);
      }

      return array;
    },
    calcSum: debounce(function () {
      const data = this.getConfData();
      if (!this.loading) {
        this.$store
          .dispatch('moduleVPS/fetchTariffCalc', {
            ...data,
            elid: this.tariff.id,
            account: this.profile.account,
          })
          .then(data => {
            if (data && data.model && data.model.cost) {
              this.sum = parseFloat(data.model.cost);
              this.detailsData = {
                details: data.model.cost_details,
                total: this.sum,
                expireDate: new Date(data.model.expiredate),
                period: this.tariff.period_id,
              };
            }
          })
          .catch(e => console.error(e))
          .finally(() => (this.loading = false));
      }
    }, 500),
    fetchExt(id) {
      return this.$store.dispatch('moduleVPS/fetchAddons', id);
    },
    getConfData() {
      return this.configurator.reduce((acc, addon) => {
        // console.log(addon);
        // console.log(addon.currentValue);
        acc[addon.name] = addon.currentValue;
        return acc;
      }, {});
    },
    getFormData() {
      return {
        ...this.getConfData(),
        elid: this.tariff.id,
        account: this.profile.account,
        func: this.tools.edit.func,
      };
    },
    sendToBasket() {
      this.isSendingToBasket = true;
      const params = {
        clicked_button: 'basket',
        newface: 'on',
      };
      this.$gtm.trackEvent({
        event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
        category: 'vdc',
        action: 'click',
        label: 'basket',
        value: 5000,
        noninteraction: false, // Optional
      });
      this.save(params, 'basket', 'isSendingToBasket');
    },
    sendToPay() {
      this.$gtm.trackEvent({
        event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
        category: 'vdc',
        action: 'click',
        label: 'pay',
        value: 5000,
        noninteraction: false, // Optional
      });
      this.isSending = true;
      const params = {
        ...this.getFormData(),
        clicked_button: 'basket',
        newbasket: 'on',
      };
      this.showResFunc.success = false;
      this.$store
        .dispatch('moduleVPS/updateDetail', params)
        .then(data => {
          this.$store.dispatch('moduleBasket/fetchBasket');
          if (data && data.ok && data.ok.type && data.ok.type === 'form' && data.ok.v) {
            const { billorder } = qs.parse(data.ok.v);
            this.startParams = { billorder };
            this.runWizardPay()
              .then(async data => {
                const res = await this.handleRedirect(data.ok);
                if (res.ok) {
                  const text =
                    res.func === 'redirect'
                      ? this.$t('success.redirect')
                      : this.$t('success.pay', { num: this.$n(this.sum, 'currency') });
                  this.showSuccessModal(text);
                }
                //this.updateBalance();
                this.updatePageData();
              })
              .catch(() => {
                const basketItem = this.$store.state.moduleBasket.shadow[billorder][0];
                if (basketItem) {
                  this.$store.dispatch('moduleBasket/removeFromBasket', basketItem).finally(() => {
                    this.$store.dispatch('moduleBasket/fetchBasket');
                  });
                }
              });
          }
        })
        .catch(e => this.showError(e))
        .finally(() => (this.isSending = false));
    },
    save(payload = {}, action = 'update', loader = 'isSending') {
      this[loader] = true;
      const params = {
        ...this.getFormData(),
        ...payload,
      };
      this.$store
        .dispatch('moduleVPS/updateDetail', params)
        .then(data => {
          if (data.ok) {
            setTimeout(async () => {
              // console.log('await');
              this[loader] = false;
              this.showSuccessModal(
                this.$t(`success.${action}`, { num: this.$n(this.sum, 'currency') })
              );
              await this.updateVpsStatus(this.tariff.id, true);
              await this.updatePageData();
            }, 15 * 1000);
          }
        })
        .catch(e => {
          this[loader] = false;
          this.showError(e);
        });
      // .finally(() => (this[loader] = false));
    },
    updatePageData() {
      console.log('updatePageData');
      return Promise.all([
        this.$store.dispatch('moduleVPS/fetchDetail', { id: this.tariff.id }),
        this.$store.dispatch('moduleProfile/setProfileInfo'),
        this.$store.dispatch('moduleBasket/fetchBasket'),
      ]).then(() => this.fetchExt(this.pricelistId));
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "res": {
      "def": "Итого",
      "pay": "К оплате",
      "refund": "Вернём"
    },
    "order": "Заказать",
    "pay": "Оплатить",
    "basket": "В корзину",
    "save": "Сохранить",
    "disabled": {
      "title": "Изменение параметров недоступно",
      "text": "Виртуальный сервер находится в процессе обработки.",
      "note": "Дождитесь завершения обработки"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Конфигурация успешно обновлена, {num} списано с лицевого счета",
      "redirect": "Конфигурация успешно обновлена, продолжите оплату и дождитесь обновления данных",
      "update": "Изменения успешно сохранены."
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-change {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }

  &__footer {
    flexy(flex-end, flex-end);
    flex-direction: column;

    +breakpoint(sm-and-up) {
      flex-direction: row;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  &__summary {
    flexy(flex-end, center);
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }

    &-text {
      margin-right: 1.5rem;
    }
    &-sum {
      text-transform: lowercase;
    }
  }

  &__text {
    margin-bottom: 1rem;
    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }
  }

  &__order-btns {
    margin: -0.5rem;
  }

  &__order-btn {
    min-width: 160px;
    margin: 0.5rem;
    width: calc(100% - 1rem);

    +breakpoint(ms-and-up) {
      width: auto;
    }
  }

  &-config {
    +breakpoint(sm-and-up) {
      margin-top: 1rem;
    }

    &__item {
      & + & {
        margin-top: 1.5rem;

        +breakpoint(sm-and-up) {
          margin-top: 2.5rem;
        }
      }
    }
  }

  &__more {
    margin-left: 1rem;

    &-btn {
      color: $color-light.medium;
      opacity: 0.5;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
  &__details {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: auto;
      max-width: calc(100vw - 3.25rem);
    }
  }
}
</style>
