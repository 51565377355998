export default {
  data() {
    return {
      oldState: '',
      timerId: '',
      timerCnt: 0,
    };
  },
  computed: {},
  methods: {
    async updateVpsStatus(id, needUpdate = false) {
      this.timerId = setTimeout(() => {
        if (needUpdate) {
          this.timerCnt = 4;
          // console.log('needUpdate');
          this.$store
            .dispatch('moduleVPS/fetchDetail', { id: id, detail: true })
            .then(async data => {
              // console.log(data.model.status, '*******************');
              await this.updateVpsStatus(id, true);
            });
        } else {
          this.isStartUpdateTariff = true;
          this.$store
            .dispatch('moduleVPS/fetchDetail', { id: id, detail: true })
            .then(async data => {
              // console.log(data.model.status, '-------------');
              this.timerCnt = 1;
              if (['1', '4', '5'].includes(data.model.status)) {
                // if (this.timerCnt < 5) this.timerCnt += 1;
                await this.updateVpsStatus(id);
              } else {
                this.timerCnt = 0;
                this.isStartUpdateTariff = false;
                this.$store
                  .dispatch('moduleVPS/fetchDetail', { id: this.tariff.id })
                  .then(() => {});
                clearTimeout(this.timerId);
              }
            });
        }
      }, 1000 * 15 * this.timerCnt);
    },
  },
};
